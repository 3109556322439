<template>
  <VLink variant="secondary" :class="$style.button" @click="openLoginModal">
    {{ title }}
  </VLink>
</template>

<script setup lang="ts">
import LoginModal from "~/components/layout/modals/LoginModal.vue";

defineProps({
  title: {
    type: String,
    default: "Войти",
  },
});

const { $modal } = useNuxtApp();

function openLoginModal() {
  $modal.open(markRaw(LoginModal), {});
}
</script>

<style lang="scss" module>
.button {
  font-size: 1.6rem;
  line-height: 115%;

  @include respond-to(xs) {
    font-size: 12px;
  }
}
</style>
