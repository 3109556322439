<template>
  <VLink variant="secondary" @click="openRegistrationModal">
    {{ title }}
  </VLink>
</template>

<script setup lang="ts">
import RegistrationModal from "~/components/layout/modals/RegistrationModal.vue";

defineProps({
  title: {
    type: String,
    default: "Зарегистрироваться",
  },
});
const { $modal } = useNuxtApp();

function openRegistrationModal() {
  $modal.open(markRaw(RegistrationModal), {});
}
</script>
