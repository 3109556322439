import { storeToRefs } from "pinia";
import { authApi } from "~/utils/api/auth";
import { useUserStore } from "~/stores/useUserStore";
import { userApi } from "~/utils/api/user";
import MessageModal from "~/components/layout/modals/MessageModal.vue";

export default function useAuth() {
  const email = ref("");
  const password = ref("");
  const error = ref({});
  const userStore = useUserStore();
  const { isActivated } = storeToRefs(userStore);
  const { setToken, loadUser } = useUserStore();

  const localePath = useLocalePath();

  const signIn = async () => {
    try {
      const { access } = await authApi.signIn({
        email: email.value.toLowerCase(),
        password: password.value,
      });
      setToken(access);
      loadUser().then(() => {
        navigateTo(localePath("/account/orders"));
      });
    } catch (e: any) {
      error.value = e.data.data;
    }
  };

  const activate = async (code: string) => {
    const { $modal } = useNuxtApp();
    try {
      await userApi.activateAccount(code);
      $modal.open(markRaw(MessageModal), {
        title: "Уведомление",
        message: "Аккаунт успешно активирован",
      });
      await useUserStore().loadUser();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    email,
    password,
    signIn,
    error,
    isActivated,
    activate,
  };
}
