<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <form
      ref="modal"
      :class="$style.RegistrationModal"
      class="common-modal"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleSubmit"
    >
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">{{ t("Регистрация") }}</h1>
        <SvgoIconClose
          class="icon-close"
          @click="$emit('close')"
        ></SvgoIconClose>
      </div>
      <div :class="$style.inputs">
        <VInput
          v-model="email"
          placeholder="Email"
          required
          autofocus
          :error="errors?.email"
          @update:model-value="clearError('email')"
        ></VInput>
        <InputPassword
          v-model="password"
          :placeholder="t('Пароль')"
          required
          :error="errors?.password"
          @update:model-value="clearError('password')"
        ></InputPassword>
        <InputPassword
          v-model="passwordAgain"
          :placeholder="t('Подтвердите пароль')"
          required
          :error="errors?.passwordAgain"
          @update:model-value="clearError('passwordAgain')"
        ></InputPassword>
      </div>
      <VCheckbox v-model="agree" :class="$style.checkbox">
        {{ t("Соглашаюсь с") }}
        <VLink
          variant="secondary"
          to="/rules"
          target="_blank"
          :class="$style.link"
          >{{ t("правилами сервиса") }}</VLink
        >
      </VCheckbox>
      <VButton
        :class="$style.registration"
        block
        type="submit"
        :disabled="!agree"
        :label="t('Зарегистрироваться')"
      ></VButton>
      <div :class="$style.button">
        <LoginLink :title="t('Уже есть аккаунт')"></LoginLink>
      </div>
    </form>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { authApi } from "~/utils/api/auth";
import MessageModal from "~/components/layout/modals/MessageModal.vue";
import { useFormErrors } from "~/composables/useFormErrors";

defineProps({
  visible: Boolean,
});

const { t } = useI18n({
  useScope: "local",
});

const agree = ref(true);
const email = ref("");
const password = ref("");
const passwordAgain = ref("");
const isLoading = ref(false);

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});

const { $modal } = useNuxtApp();

const { errors, setErrors, clearError } = useFormErrors();

async function handleSubmit() {
  if (isLoading.value) {
    return;
  }
  if (!agree.value) {
    return;
  }
  try {
    isLoading.value = true;
    const data = {
      email: email.value.toLowerCase(),
      password: password.value,
      passwordAgain: passwordAgain.value,
      type: "regular",
    };
    const response = await authApi.register(data);
    if (response) {
      $modal.open(markRaw(MessageModal), {
        title: t("Регистрация прошла успешно!"),
        message: `${t("Осталось активировать аккаунт.")} \n ${t(
          "Для этого перейдите по ссылке направленной в письме на вашу почту.",
        )}`,
      });
    }
  } catch (error: any) {
    setErrors(error.data);
  } finally {
    isLoading.value = false;
  }
}
</script>

<i18n lang="json">
{
  "ru": {
    "Соглашаюсь с": "Соглашаюсь с",
    "правилами сервиса": "правилами сервиса",
    "Регистрация прошла успешно!": "Регистрация прошла успешно!",
    "Осталось активировать аккаунт.": "Осталось активировать аккаунт.",
    "Для этого перейдите по ссылке направленной в письме на вашу почту.": "Для этого перейдите по ссылке направленной в письме на вашу почту."
  },
  "en": {
    "Соглашаюсь с": "I agree with the ",
    "правилами сервиса": "rules of the service",
    "Регистрация прошла успешно!": "Registration was successful!",
    "Осталось активировать аккаунт.": "It remains to activate the account.",
    "Для этого перейдите по ссылке направленной в письме на вашу почту.": "To do this, follow the link sent in the letter to your email."
  }
}
</i18n>

<style lang="scss" module>
.title {
  text-align: center;
  width: 100%;
}

.header {
  margin-bottom: 3rem;

  @include respond-to(xs) {
    margin-bottom: 20px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.checkbox {
  margin-bottom: 2rem;
  color: var(--text-color-6);

  @include respond-to(xs) {
    font-size: 14px;
  }
}

.inputs,
.checkbox {
  @include respond-to(xs) {
    margin-bottom: 15px;
  }
}

.registration {
  margin-bottom: 1.5rem;

  @include respond-to(xs) {
    margin-bottom: 10px;
  }
}

.button {
  text-align: center;
}

.link {
  margin-left: 0.3rem;

  @include respond-to(xs) {
    font-size: 14px;
  }
}
</style>
