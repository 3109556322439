<template>
  <ModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
  >
    <form
      ref="modal"
      :class="$style.LoginModal"
      class="common-modal"
      @submit.prevent="handleSubmit"
      @keydown.enter.prevent="handleSubmit"
    >
      <div class="modal-header" :class="$style.header">
        <h1 class="h4" :class="$style.title">
          {{ t("Вход в личный кабинет") }}
        </h1>
        <SvgoIconClose
          class="icon-close"
          @click="$emit('close')"
        ></SvgoIconClose>
      </div>
      <transition name="expand">
        <p v-if="error.detail" :class="$style.error" class="common-text">
          {{ error.detail }}
        </p>
      </transition>
      <div :class="$style.inputs">
        <VInput
          v-model="email"
          :placeholder="$t('Логин')"
          autofocus
          :error="error.email"
          @input="error.email = ''"
        />
        <InputPassword
          v-model="password"
          :placeholder="$t('Пароль')"
          :error="error?.password"
          @input="error.password = ''"
        />
      </div>
      <VCheckbox v-model="remember" :class="$style.checkbox">{{
        $t("Запомнить меня")
      }}</VCheckbox>
      <VButton
        :class="$style.login"
        type="submit"
        block
        :label="$t('Войти')"
        :disabled="isLoading"
      />
      <div :class="$style.buttons">
        <VLink
          variant="secondary"
          :class="$style.button"
          @click="openForgetModal"
        >
          {{ $t("Забыли пароль") }}
        </VLink>
        <RegistrationLink
          :title="$t('Зарегистрироваться')"
          :class="$style.button"
        />
      </div>
    </form>
  </ModalWrapper>
</template>

<script setup lang="ts">
import RegistrationLink from "~/components/common/RegistrationLink.vue";
import ForgetModal from "~/components/layout/modals/ForgetModal.vue";

defineProps({
  visible: Boolean,
});

const { t } = useI18n({
  useScope: "local",
});

const emit = defineEmits(["after-leave", "after-enter", "close"]);

const { $modal } = useNuxtApp();

const remember = ref(false);

function openForgetModal() {
  $modal.open(markRaw(ForgetModal), {});
}

const modal = ref(null);

onClickOutside(modal, () => {
  emit("close");
});

const { email, password, signIn, error } = useAuth();
const isLoading = ref(false);
async function handleSubmit() {
  if (isLoading.value) return;
  isLoading.value = true;
  await signIn();
  isLoading.value = false;
}
</script>

<i18n lang="json">
{
  "ru": {
    "Вход в личный кабинет": "Вход в личный кабинет"
  },
  "en": {
    "Вход в личный кабинет": "Login"
  }
}
</i18n>

<style lang="scss" module>
.title {
  text-align: center;
  width: 100%;
}

.header {
  margin-bottom: 3rem;

  @include respond-to(xs) {
    margin-bottom: 20px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.checkbox {
  margin-bottom: 2rem;
  color: var(--text-color-6);

  @include respond-to(xs) {
    font-size: 14px;
  }
}

.inputs,
.checkbox {
  @include respond-to(xs) {
    margin-bottom: 15px;
  }
}

.login {
  margin-bottom: 1.5rem;

  @include respond-to(xs) {
    margin-bottom: 10px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  font-size: 1.6rem;
  line-height: 115%;

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.error {
  text-align: center;
  color: $brand-red;
  margin-bottom: 2rem;
  line-height: 140%;
}
</style>
